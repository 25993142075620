<template>
    <div>
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> <span style="margin-left:5px;"> {{ $t("label.yardClient") }}</span>
                </div>
                <div style="width: 100%;padding:10px;" class="d-flex text-especial">
                    <div class="flex-fill" style="margin-top:20px;background: #E8F0F4;padding: 5px;padding-top: 10px;">
                        <div style="border-bottom:4px solid #1A3760;margin-left:50px;margin-right:50px;text-align: center;margin-bottom: 10px;"><h6>{{ $t('label.clientYard') }}</h6></div>
                        <div class="d-flex">
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#1A3760;color:#FFF;text-align: center;height: 45.5px;">
                                            <tr>
                                                <th>{{ $t('label.yard') }}</th>
                                                <th>{{ $t('label.client') }}</th>
                                                <th>{{ $t('label.transportationCarrier') }}</th>
                                                <th>{{ $t('label.Gamela') }} ({{ $t('label.voyage') }})</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ item.YardName }}</td>
                                                <td style="height: 45.5px;">{{ item.YardClientName }}</td>
                                                <td style="height: 45.5px;">{{ item.CarrierAlias }}</td>
                                                <td style="height: 45.5px;">{{ item.CantImplement }}</td>
                                            </tr>
                                            <tr class="negrita-fila">
                                                <td colspan="3" class="text-right"><strong>{{ $t('label.totalVoyageDone') }}</strong></td>
                                                <td>{{ total.Gamela }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-fill" style="margin-top:20px;background: #E8F0F4;padding: 5px;padding-top: 10px;">
                        <div style="border-bottom:4px solid #1A3760;margin-left:50px;margin-right:50px;text-align: center;margin-bottom: 10px;"><h6>{{ $t('label.Gamela')+'S' }}</h6></div>
                        <div class="d-flex">
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#8497B0;color:#FFF;text-align: center;height: 45.5px;">
                                            <tr>
                                                <th>{{ $t('label.onCircuit') }}</th>
                                                <th>{{ $t('label.Loadeds') }}</th>
                                                <th>{{ $t('label.Annulled') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ item.ImplementJson.CantInCircuit }}</td>
                                                <td style="height: 45.5px;">{{ item.ImplementJson.CantEmbarked }}</td>
                                                <td style="height: 45.5px;">{{ item.ImplementJson.CantAnnulled }}</td>
                                            </tr>
                                            <tr class="negrita-fila">
                                                <td><strong>{{ total.CantInCircuit }}</strong></td>
                                                <td><strong>{{ total.CantEmbarked }}</strong></td>
                                                <td><strong>{{ total.CantAnnulled }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-fill" style="margin-top:20px;background: #E8F0F4;padding: 5px;padding-top: 10px;">
                        <div style="border-bottom:4px solid #1A3760;margin-left:50px;margin-right:50px;text-align: center;margin-bottom: 10px;"><h6>{{ $t('label.Scale')+'S' }}</h6></div>
                        <div class="d-flex">
                            <div class="flex-fill" style="margin-left:5px;margin-right:5px;">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead style="background:#FF9900;color:#FFF;text-align: center;height: 45.5px;">
                                            <tr>
                                                <th>{{ $t('label.qtyWeighed') }}</th>
                                                <th>{{ $t('label.tonHeavy') }}</th>
                                                <th>{{ $t('label.qtyWithoutWeighing') }}</th>
                                                <th>{{ $t('label.tonEstimated') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody style="text-align:center">
                                            <tr v-for="(item,index) in carrierArray" :key="index">
                                                <td style="height: 45.5px;">{{ item.TruckWeighingScaleJson.CantWeighed }}</td>
                                                <td style="height: 45.5px;">{{ valuemoney(item.TruckWeighingScaleJson.TontWeighed) }}</td>
                                                <td style="height: 45.5px;">{{ item.TruckWeighingScaleJson.CantNotWeighed }}</td>
                                                <td style="height: 45.5px;">{{ valuemoney(item.TruckWeighingScaleJson.TonEstimated) }}</td>
                                            </tr>
                                            <tr class="negrita-fila">
                                                <td><strong>{{ total.CantWeighed }}</strong></td>
                                                <td><strong>{{ valuemoney(total.TontWeighed) }}</strong></td>
                                                <td><strong>{{ total.CantNotWeighed }}</strong></td>
                                                <td><strong>{{ valuemoney(total.TonEstimated) }}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState  } from 'vuex';
    //methods
    function valuemoney(value){
        if(value){
            let newValue = parseFloat(value).toFixed(2);
            var options = {minimumFractionDigits: 2, maximumFractionDigits: 2};
            return new Intl.NumberFormat("de-DE",options).format(newValue);
        }
        return "0,00";
    }
    //computed
    function carrierArray(){
        let data = [];
        this.ClientYardSummary.map(async(item) => {
            let ImplementJson = item.ImplementJson ? item.ImplementJson[0] : { CantAnnulled:0,CantEmbarked:0,CantInCircuit:0 };
            let TruckWeighingScaleJson = item.TruckWeighingScaleJson ? item.TruckWeighingScaleJson[0] : { CantNotWeighed:0,CantWeighed:0,TonEstimated:0,TontWeighed:0 };
            data.push({
                ...item,
                ImplementJson: ImplementJson,
                TruckWeighingScaleJson: TruckWeighingScaleJson,
            })
        })
        return data;
    }
    function total(){
        let count = {};
        this.carrierArray.map(async(item) => {
            //Total voyage
            count.Gamela = item.CantImplement + ( count.Gamela ? count.Gamela : 0);
            //Gamela
            count.CantInCircuit = item.ImplementJson.CantInCircuit + ( count.CantInCircuit ? count.CantInCircuit : 0);
            count.CantEmbarked = item.ImplementJson.CantEmbarked + ( count.CantEmbarked ? count.CantEmbarked : 0);
            count.CantAnnulled = item.ImplementJson.CantAnnulled + ( count.CantAnnulled ? count.CantAnnulled : 0);
            //Scale
            count.CantWeighed = item.TruckWeighingScaleJson.CantWeighed + ( count.CantWeighed ? count.CantWeighed : 0);
            count.TontWeighed = item.TruckWeighingScaleJson.TontWeighed + ( count.TontWeighed ? count.TontWeighed : 0);
            count.CantNotWeighed = item.TruckWeighingScaleJson.CantNotWeighed + ( count.CantNotWeighed ? count.CantNotWeighed : 0);
            count.TonEstimated = item.TruckWeighingScaleJson.TonEstimated + ( count.TonEstimated ? count.TonEstimated : 0);
        })
        return count;
    }
    export default {
        name: 'ClienteYard',
        props:{
            ClientYardSummary:{
                type: Array,
                default: () => [],
            }
        },
        methods:{
            valuemoney
        },
        computed:{
            total,
            carrierArray,
            ...mapState({
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
            })
        } 
    }
</script>

<style scoped>
    .negrita-fila{
        font-weight: bold;
    }
</style>